import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RoundFilledTickSvgComponent } from '@uc/shared/svg';
import { LeadsHeaderData } from '@uc/web/shared/data-models';

@Component({
	selector: 'uc-leads-header',
	templateUrl: './leads-header.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, RoundFilledTickSvgComponent],
})
export class LeadsHeaderComponent {
	@Input() details!: LeadsHeaderData;
}
