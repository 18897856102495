import { Component, forwardRef, HostBinding, Optional } from '@angular/core';
import {
	ControlContainer,
	FormsModule,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
} from '@angular/forms';
import { BaseControlComponent } from '../base-control/base-control.component';
import { CommonModule } from '@angular/common';
import { FormErrorComponent } from '../form-error/form-error.component';
import { DateFormatterDirective } from '@uc/utilities';

@Component({
	selector: 'uc-date-input',
	templateUrl: './date-input.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DateInputComponent),
			multi: true,
		},
	],
	imports: [
		DateFormatterDirective,
		FormErrorComponent,
		ReactiveFormsModule,
		FormsModule,
		CommonModule,
	],
})
export class DateInputComponent extends BaseControlComponent {
	placeholder = 'Date of Birth*';
	output = '';

	constructor(@Optional() protected controlContainer: ControlContainer) {
		super(controlContainer);
	}

	updateValue(value: any): void {
		this.value = value;
		this.onChange(this.value);
	}

	@HostBinding('attr.placeholder')
	focus(): void {
		if (this.placeholder === 'Date of Birth') {
			this.placeholder = 'DD/MM/YYYY';
		}
	}

	@HostBinding('attr.placeholder')
	focusOut(): void {
		if (this.placeholder === 'DD/MM/YYYY') {
			this.placeholder = 'Date of Birth';
		}
	}
}
