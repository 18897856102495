import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	forwardRef,
	HostListener,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Optional,
	PLATFORM_ID,
	QueryList,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { Subscription, take } from 'rxjs';
import { BaseControlComponent } from '../base-control/base-control.component';
import { ArrowDownSvgComponent, TickSvgComponent } from '@uc/shared/svg';
import { FormErrorComponent } from '../form-error/form-error.component';
import { HelperService } from '@uc/utilities';

@Component({
	selector: 'uc-custom-select',
	templateUrl: './custom-select.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CustomSelectComponent),
			multi: true,
		},
	],
	imports: [
		FormErrorComponent,
		TickSvgComponent,
		ArrowDownSvgComponent,
		CommonModule,
		ReactiveFormsModule,
	],
})
export class CustomSelectComponent
	extends BaseControlComponent
	implements OnInit, OnDestroy
{
	@Input() placeholder!: string;
	@Input() isArrayOfObjects!: boolean;
	@Input() key!: string;
	@Input() autocomplete = '';
	@Input() disableTick!: boolean;

	@Input() data: any;
	private fullData!: any[];

	@Input() labelName!: string;
	showLabel = false;

	@Input() errorMsg!: string;

	@ViewChild('searchInput') searchInput!: ElementRef<HTMLElement>;
	@ViewChild('container') container!: ElementRef<HTMLElement>;
	@ViewChildren('options') allOptions!: QueryList<any>;

	focusedValueIndex: number | null = 0;
	displayBox = false;

	private firstSearch = true;
	private screenHeight!: number;
	private platform: string;
	private controlSub!: Subscription;

	constructor(
		@Inject(PLATFORM_ID) platformId: string,
		@Optional() protected controlContainer: ControlContainer,
		private helper: HelperService,
		private cd: ChangeDetectorRef,
	) {
		super(controlContainer);
		this.platform = platformId;
		this.getScreenSize();
	}

	ngOnInit() {
		this.controlSub = this.control.valueChanges.pipe(take(1)).subscribe((e) => {
			this.control.setValue(e, { emitEvent: false });
			this.cd.detectChanges();
		});
	}

	ngOnDestroy() {
		if (this.controlSub) this.controlSub.unsubscribe();
	}

	@HostListener('window:resize', [])
	getScreenSize() {
		if (isPlatformBrowser(this.platform)) {
			this.screenHeight = window.innerHeight;
		}
	}

	checkShowing() {
		if (this.displayBox) {
			this.showLabel = true;
		} else {
			this.showLabel = false;
			//to reset options to all when user selects / closes the dropdown
			this.onSearch('');
		}
	}

	showSelectBox(showDropdown: boolean | null = null): void {
		if (showDropdown === null) {
			this.displayBox = !this.displayBox;
		} else {
			this.displayBox = showDropdown;
		}

		//focus on input when dropdown appears
		if (this.displayBox) {
			setTimeout(() => {
				this.searchInput.nativeElement.focus();
				this.scrollSelectIntoView();
			}, 100);
		}

		this.checkShowing();
	}

	//scrolls the whole dropdown into view if e.g. at the bottom of page
	scrollSelectIntoView(): void {
		const elemPosition = this.container?.nativeElement?.getBoundingClientRect();
		const positionY = elemPosition.y;
		if (this.screenHeight - positionY < 400) {
			this.container.nativeElement.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}
	}

	//when user navigates with keyboard, the options in dropdown become visible
	scrollOptionIntoView(): void {
		if (!this.focusedValueIndex) return;

		const options = this.allOptions.filter((ele, i) => this.focusedValueIndex === i);
		options[0].nativeElement.scrollIntoView({ block: 'nearest' });
	}

	focusPrev(): void {
		if (
			this.focusedValueIndex === null ||
			this.focusedValueIndex > this.data.length
		) {
			this.focusedValueIndex = 0;
			return;
		} else if (this.focusedValueIndex <= 0) {
			return;
		} else {
			this.focusedValueIndex -= 1;
			this.scrollOptionIntoView();
		}
	}

	focusNext(): void {
		if (
			this.focusedValueIndex === null ||
			this.focusedValueIndex > this.data.length
		) {
			this.focusedValueIndex = 0;
			return;
		} else if (this.focusedValueIndex + 1 >= this.data.length) {
			return;
		} else {
			this.focusedValueIndex += 1;
			this.scrollOptionIntoView();
		}
	}

	onSelectOption(uni: any): void {
		if (uni.selected === 1 && this.value !== uni) return;

		this.value = uni;
		this.onChange(this.value);
		this.showSelectBox(false);
		this.showLabel = true;
	}

	onKeyDown(event: KeyboardEvent): void {
		event.stopPropagation();

		const { key } = event;

		if (this.focusedValueIndex === null) {
			this.focusedValueIndex = 0;
		}

		if (key === 'ArrowDown') {
			this.focusNext();
		} else if (key === 'ArrowUp') {
			this.focusPrev();
		} else if (key === 'Enter') {
			event.preventDefault();
			this.onSelectOption(this.data[this.focusedValueIndex]);
		} else if (key === 'Escape') {
			this.displayBox = false;
		} else if (key === 'Space') {
			this.displayBox = true;
		}

		this.checkShowing();
	}

	onSearch(searchTerm: string) {
		if (!Array.isArray(this.data)) return;

		//copy initial search data over only of async has completed.
		if (this.firstSearch) {
			this.fullData = this.data;
			this.firstSearch = false;
		}

		this.focusedValueIndex = 0;

		// when user resets search
		if (!searchTerm) {
			this.data = this.fullData;
		} else {
			const filteredData = this.fullData.filter((element: any) => {
				if (this.helper.isObject(element)) {
					return element[this.key]
						.toString()
						.toLowerCase()
						.includes(searchTerm.toLowerCase());
				} else {
					return element
						.toString()
						.toLowerCase()
						.includes(searchTerm.toLowerCase());
				}
			});

			this.data = filteredData;
		}
	}
}
