import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	input,
	OnChanges,
	signal,
} from '@angular/core';
import { RegexService } from '@uc/utilities';

@Component({
	selector: 'uc-password-strength',
	templateUrl: './password-strength.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule],
})
export class PasswordStrengthComponent implements OnChanges {
	password = input.required<string>();

	passStrength = signal<string | null>(null);
	passLength = signal<number>(0);
	upperLowercase = signal(false);

	constructor(private _regex: RegexService) {}

	ngOnChanges(): void {
		const password = this.password();
		this.passStrength.set(this.validatePasswordStrength(password));
		this.passLength.set(password.length);
		this.upperLowercase.set(/[a-z]/.test(password) && /[A-Z]/.test(password));
	}

	validatePasswordStrength(password: string) {
		if (!password) return null;

		if (this._regex.strongPassword.test(password)) {
			return 'strong';
		} else if (this._regex.mediumPassword.test(password)) {
			return 'medium';
		} else {
			return 'low';
		}
	}
}
