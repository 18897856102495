import { Component } from '@angular/core';
import {
	CloseModalSvgComponent,
	ExclamationMarkTriangularSvgComponent,
	RingedTickSvgComponent,
} from '@uc/shared/svg';

import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import { TOASTR } from '../animations/toastr.animations';

@Component({
	selector: 'uc-notification',
	templateUrl: './notification.component.html',
	animations: [TOASTR],
	preserveWhitespaces: false,
	imports: [
		CloseModalSvgComponent,
		RingedTickSvgComponent,
		ExclamationMarkTriangularSvgComponent,
	],
})
export class NotificationComponent extends Toast {
	constructor(
		protected override toastrService: ToastrService,
		public override toastPackage: ToastPackage,
	) {
		super(toastrService, toastPackage);
	}
}
