import { Component, Input, Optional, forwardRef } from '@angular/core';

import {
	ControlContainer,
	FormsModule,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
} from '@angular/forms';
import { BaseControlComponent } from '../base-control/base-control.component';
import { PhoneCountryCodeFieldService } from './phone-country-code-field.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
	selector: 'uc-phone-country-code-field',
	imports: [FormsModule, ReactiveFormsModule],
	templateUrl: './phone-country-code-field.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PhoneCountryCodeFieldComponent),
			multi: true,
		},
	],
})
export class PhoneCountryCodeFieldComponent extends BaseControlComponent {
	@Input() idAttr = 'calling-country-code';

	countryCodes = toSignal(this._phoneCountryCodeFieldSrv.getCountryCodes(), {
		initialValue: [],
	});

	constructor(
		@Optional() protected controlContainer: ControlContainer,
		private _phoneCountryCodeFieldSrv: PhoneCountryCodeFieldService,
	) {
		super(controlContainer);
	}

	updateValue(event: Event) {
		this.value = (event.target as HTMLSelectElement)?.value;
		this.onChange(this.value);
	}
}
