import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoginSuccessService, emailValidator } from '@uc/web/core';
import { ModalService } from '../modal/modal.service';

import { Role, AuthenticationService, errorLogin } from '@uc/shared/authentication';
import { CloseModalComponent } from '../close-modal/close-modal.component';
import { Router, RouterModule } from '@angular/router';
import { FormErrorComponent } from '../form-elements/form-error/form-error.component';
import { InputFieldComponent } from '../form-elements/input-field/input-field.component';
import { PasswordFieldComponent } from '../form-elements/password-field/password-field.component';
import { Store } from '@ngrx/store';

@Component({
	selector: 'uc-login-modal',
	templateUrl: './login-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		PasswordFieldComponent,
		InputFieldComponent,
		FormErrorComponent,
		CloseModalComponent,
		ReactiveFormsModule,
		RouterModule,
	],
})
export class LoginModalComponent {
	loginForm = this._fb.group({
		email: ['', [Validators.required, emailValidator]],
		password: ['', [Validators.required]],
	});

	formSubmitted = signal(false);
	formError = signal(false);
	isLoading = signal(false);
	redirectToRegisterPage = signal(!this._router.url.includes('degree-quiz/register'));

	private _initialTime = Date.now();

	constructor(
		private _fb: NonNullableFormBuilder,
		private _store: Store,
		private _router: Router,
		private _modalSrv: ModalService,
		private _authSrv: AuthenticationService,
		private _loginSuccessSrv: LoginSuccessService,
	) {}

	onSubmit() {
		this.isLoading.set(false);
		this.formError.set(false);

		this.formSubmitted.set(true);
		if (this.loginForm.invalid) return;
		this.isLoading.set(true);

		this._authSrv
			.login(this.loginForm.getRawValue())
			.then((response) => {
				this.closeModalPanel();

				if (response.role === Role.STUDENT) {
					this._loginSuccessSrv.handleUserResponse(
						response,
						this.loginForm.getRawValue().email,
						this._initialTime,
					);
					// reload page if user signs in successfully (important for bookmark functionality)
					location.reload();
				} else if (response.role === Role.CLIENT) {
					this._loginSuccessSrv.handleClientResponse(response);
				}
			})
			.catch((error) => {
				console.error(error.error);
				this._store.dispatch(errorLogin(error));
				this.formError.set(true);
			})
			.finally(() => {
				this.isLoading.set(false);
			});
	}

	closeModalPanel() {
		this._modalSrv.close();
	}
}
