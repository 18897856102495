import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentApiService } from '@uc/utilities';
import { CustomCookieService } from '@uc/shared/authentication';
import { Observable, of } from 'rxjs';
import {
	ChangeApplicationDetailsPayload,
	ChangePasswordPayload,
	ClientDetails,
	StudentDetails,
	ChangeUserDetailsPayload,
	UserFavouritesCourse,
	UserFavouritesUniversity,
} from '@uc/web/shared/data-models';

@Injectable({ providedIn: 'root' })
export class UserDetailsApiService {
	constructor(
		private _http: HttpClient,
		private _customCookieSrv: CustomCookieService,
		private _apiSrv: EnvironmentApiService,
	) {}

	getStudentDetails(token?: string): Observable<StudentDetails | null> {
		const _token = token ?? this._customCookieSrv.get('token');
		const headers = new HttpHeaders({
			Authorization: `Bearer ${_token}`,
		});

		return this._http.get<StudentDetails>(`${this._apiSrv.API_URL}student`, {
			headers,
			transferCache: false,
		});
	}

	getStudentFavouriteCourses() {
		return this._http.get<UserFavouritesCourse[]>(
			this._apiSrv.API_URL + 'student/favourite-courses',
			{
				transferCache: false,
			},
		);
	}

	getStudentFavouriteUnis() {
		return this._http.get<UserFavouritesUniversity[]>(
			this._apiSrv.API_URL + 'student/favourite-universities',
			{
				transferCache: false,
			},
		);
	}

	updateStudentDetails(url: string, args: any) {
		const token = this._customCookieSrv.get('token');
		if (!token) return of(null);

		return this._http.put<any>(url, args);
	}

	changePassword(args: ChangePasswordPayload) {
		return this.updateStudentDetails(`${this._apiSrv.API_URL}student/account`, args);
	}

	// change name, phone, address
	changeAccountDetails(args: ChangeUserDetailsPayload) {
		return this.updateStudentDetails(`${this._apiSrv.API_URL}student/details`, args);
	}

	// change study year, subject, degree type, preferred unis
	changeApplicationDetails(args: ChangeApplicationDetailsPayload) {
		return this.updateStudentDetails(
			`${this._apiSrv.API_URL}student/preferences`,
			args,
		);
	}

	getTokenBySSO(id: string): Observable<{ token: string }> {
		const params = {
			id,
		};

		return this._http.post<{ token: string }>(
			`${this._apiSrv.API_URL}account/login-by-sso`,
			params,
			{ transferCache: false },
		);
	}

	getClientDetails(token?: string): Observable<ClientDetails | null> {
		const _token = token ?? this._customCookieSrv.get('token');

		const headers = new HttpHeaders({
			Authorization: `Bearer ${_token}`,
		});

		return this._http.get<ClientDetails>(`${this._apiSrv.API_URL}client`, {
			headers,
			transferCache: false,
		});
	}
}
