import {
	Component,
	EventEmitter,
	forwardRef,
	Input,
	Optional,
	Output,
} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlComponent } from '../base-control/base-control.component';
import { CommonModule } from '@angular/common';
import { FormErrorComponent } from '../form-error/form-error.component';

@Component({
	selector: 'uc-postcode-input',
	templateUrl: './postcode-input.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PostcodeInputComponent),
			multi: true,
		},
	],
	imports: [FormErrorComponent, CommonModule],
})
export class PostcodeInputComponent extends BaseControlComponent {
	@Output() UKResident: EventEmitter<boolean> = new EventEmitter();
	@Input() placeholderText = 'UK Postcode';
	@Input() hideResidencySwitch = false;

	constructor(@Optional() protected controlContainer: ControlContainer) {
		super(controlContainer);
	}

	updateValue(value: any): void {
		this.value = value;
		this.onChange(this.value);
	}

	clickNotUk(): void {
		this.UKResident.emit(false);
	}

	formatPostcode() {
		this.value = this.value
			?.replace(/\s/g, '')
			.replace(/^(.*)(\d)/, '$1 $2')
			.toUpperCase()
			.trim();

		this.form.get(this.controlName)?.setValue(this.value);
	}
}
