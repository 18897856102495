import { Component, input, OnDestroy, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { PhoneCountryCodeFieldComponent } from '../../form-elements/phone-country-code-field/phone-country-code-field.component';
import { debounceTime, Subscription } from 'rxjs';
import { FormErrorComponent } from '../../form-elements/form-error/form-error.component';
import { InputFieldComponent } from '../input-field/input-field.component';
import {
	GENERAL_ERROR,
	LENGTH_ERROR,
	SPECIAL_CHAR_ERROR,
} from './phone-field.properties';

@Component({
	selector: 'uc-phone-field',
	imports: [
		CommonModule,
		ReactiveFormsModule,
		PhoneCountryCodeFieldComponent,
		FormErrorComponent,
		InputFieldComponent,
		PhoneCountryCodeFieldComponent,
		InputFieldComponent,
	],
	templateUrl: './phone-field.component.html',
})
export class PhoneFieldComponent implements OnInit, OnDestroy {
	parentForm = input.required<FormGroup>();
	formSubmitted = input.required<boolean>();
	placeholder = input('Mobile Number*');

	errorMessage = signal('');
	showDarkBorder = signal(false);

	get phone() {
		return this.parentForm().get('phoneDetailsForm.phone') as FormControl;
	}

	get countryCode() {
		return this.parentForm().get('phoneDetailsForm.countryCode') as FormControl;
	}

	private _formFieldSub!: Subscription | undefined;

	ngOnInit() {
		this._formFieldSub = this.parentForm()
			.get('phoneDetailsForm.phone')
			?.valueChanges.pipe(debounceTime(300))
			.subscribe((value) => {
				this.showDarkBorder.set(!!value);
				this._getErrorMessage(value);
			});
	}

	ngOnDestroy() {
		this._formFieldSub?.unsubscribe();
	}

	private _getErrorMessage(phoneValue: string) {
		let message = '';

		const hasNoneNumeric = /[^0-9]/g.test(phoneValue);
		const isValidLength = /[\d]{4,15}/g.test(phoneValue);

		if (isValidLength && hasNoneNumeric) {
			message = SPECIAL_CHAR_ERROR;
		} else if (!hasNoneNumeric && !isValidLength) {
			message = LENGTH_ERROR;
		} else {
			message = GENERAL_ERROR;
		}

		this.errorMessage.set(message);
	}
}
