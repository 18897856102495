import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
	providedIn: 'root',
})
export class SeoMetadataService {
	constructor(
		private title: Title,
		private meta: Meta,
	) {}

	twitterConfig = {
		card: 'summary_large_image',
		site: '@uni_compare',
		creator: '@uni_compare',
	};

	private decodeHTMLEntities(text: string) {
		const entities = [
			['amp', '&'],
			['apos', "'"],
			['#x27', "'"],
			['#x2F', '/'],
			['#39', "'"],
			['#039', "'"],
			['#47', '/'],
			['lt', '<'],
			['gt', '>'],
			['nbsp', ' '],
			['quot', '"'],
			['rsquo', "'"],
			['andamp', '&'],
			['lt;br&gt', '<br/>'],
			['lt;p&gt', '<p>'],
			['lt;/p&gt', '</p>'],
			['lt;a&gt', '<a>'],
			['lt;/a&gt', '</a>'],
			['pound', '£'],
			['ndash', '–'],
			['mdash', '—'],
			['/n', '<br/>'],
		];

		for (let i = 0, max = entities.length; i < max; ++i)
			text = text.replace(
				new RegExp('&' + entities[i][0] + ';', 'g'),
				entities[i][1],
			);

		return text;
	}

	updateTitle(title: string): void {
		const suffix = '| Uni Compare';
		const hasSuffix = title.includes(suffix) || title.includes('Uni Compare |');
		const editedTitle = hasSuffix ? title : `${title} ${suffix}`;
		this.title.setTitle(this.decodeHTMLEntities(editedTitle));
	}

	updateDescription(desc: string): void {
		const decodedDesc = this.decodeHTMLEntities(desc);
		this.meta.updateTag({ name: 'description', content: decodedDesc });
	}

	/*
		To be used to create twitter and OG meta tags
		Data to be passed:
		attribute: name/property
		content actual data e.g. image: data.imgname
		type: twitter/og
	*/
	createOgTags = (attribute: string, content: any, type: string) => {
		const tags = [];

		for (const key in content) {
			const obj = {
				[attribute]: `${type}:${key}`,
				content: content[key],
			};
			tags.push(obj);
		}

		this.meta.addTags(tags);
	};
}
