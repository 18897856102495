<div class="grid w-full gap-6 mt-4 sm:grid-cols-5">
	<div class="grid grid-cols-3 col-span-5 gap-2 md:col-span-3">
		<div
			class="h-3 rounded-l-lg bg-gray-50"
			[ngClass]="{
				'bg-red-500':
					passStrength() === 'low' ||
					passStrength() === 'medium' ||
					passStrength() === 'strong',
			}"
		></div>
		<div
			class="h-3 bg-gray-50"
			[ngClass]="{
				'bg-yellow-400':
					passStrength() === 'medium' || passStrength() === 'strong',
			}"
		></div>
		<div
			class="h-3 rounded-r-lg bg-gray-50"
			[ngClass]="{ 'bg-uc-green-700': passStrength() === 'strong' }"
		></div>
	</div>
</div>
<div>
	<p class="mt-3 text-sm text-gray-500">
		Password Strength:
		<span class="font-semibold text-black capitalize">{{ passStrength() }}</span>
	</p>

	<div class="py-2 mt-3 sm:border sm:border-gray-200 sm:px-6 sm:rounded-lg">
		<h4 class="text-sm font-semibold text-gray-500">Minimum password requirements</h4>
		<div class="flex mt-3">
			<div
				class="w-5 h-5 bg-gray-500 rounded-full"
				[ngClass]="{ '!bg-uc-green-700': passLength() >= 8 }"
			></div>
			<p class="ml-3 text-sm text-gray-500">Have at least 8 characters</p>
		</div>
		<div class="flex mt-3">
			<div
				class="w-5 h-5 bg-gray-500 rounded-full"
				[ngClass]="{ '!bg-uc-green-700': upperLowercase() }"
			></div>
			<p class="ml-3 text-sm text-gray-500">
				Include capital and lower case characters
			</p>
		</div>
		<h4 class="mt-3 text-sm font-semibold text-gray-500">
			To make your password stronger:
		</h4>
		<p class="mt-3 text-sm text-gray-500">Use numbers</p>
		<p class="mt-3 text-sm text-gray-500">Use Symbols (ie: &#64;, %, $, !)</p>
	</div>
</div>
