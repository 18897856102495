import { Component, forwardRef, Input, Optional } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlComponent } from '../base-control/base-control.component';
import { CommonModule } from '@angular/common';
import { UnhidePasswordSvgComponent, HidePasswordSvgComponent } from '@uc/shared/svg';
import { FormErrorComponent } from '../form-error/form-error.component';

@Component({
	selector: 'uc-password-field',
	templateUrl: './password-field.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PasswordFieldComponent),
			multi: true,
		},
	],
	imports: [
		FormErrorComponent,
		HidePasswordSvgComponent,
		UnhidePasswordSvgComponent,
		CommonModule,
	],
})
export class PasswordFieldComponent extends BaseControlComponent {
	@Input() inputType = 'password';
	@Input() idAttr!: string;
	@Input() autoComplete!: string;
	@Input() labelName!: string;
	@Input() placeholder = 'Password*';

	hidePassword = false;
	applyError = false;

	constructor(@Optional() protected controlContainer: ControlContainer) {
		super(controlContainer);
	}

	updateValue(value: string) {
		this.value = value;
		this.onChange(this.value);
	}

	togglePassword(): void {
		this.hidePassword = !this.hidePassword;
		this.inputType === 'password'
			? (this.inputType = 'text')
			: (this.inputType = 'password');
	}
}
