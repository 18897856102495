import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ModalService } from '../modal/modal.service';
import { clearingApp, ucApp } from './static/download-app-modal.properties';
import { NgOptimizedImage } from '@angular/common';
import { CdnPathPipe } from '@uc/utilities';
import { CloseModalComponent } from '../close-modal/close-modal.component';
import { DownloadAppModalFlameSvgComponent } from '@uc/shared/svg';

@Component({
	selector: 'uc-download-app-modal',
	templateUrl: './download-app-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		CdnPathPipe,
		NgOptimizedImage,
		CloseModalComponent,
		DownloadAppModalFlameSvgComponent,
	],
})
export class DownloadAppModalComponent implements OnInit {
	/**
	 * @data value is set in DownloadAppBtnComponent
	 * by calling: this.modalSrv.setData({ product: this.product });
	 * the @data variable name can't be changed
	 */
	data!: { product: 'default' | 'clearing' };
	content = ucApp;

	constructor(private modalSrv: ModalService) {}
	ngOnInit(): void {
		if (this.data.product === 'clearing') {
			this.content = clearingApp;
		}
	}

	closeModalPanel() {
		this.modalSrv.close();
	}
}
