import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
	StateUserDetails,
	_STATE_USER_DETAILS_FEATURE_KEY,
} from './user-details.reducer';

const getUserDetailsState = createFeatureSelector<StateUserDetails>(
	_STATE_USER_DETAILS_FEATURE_KEY,
);

export const getStudentDetailsData = createSelector(
	getUserDetailsState,
	(state: StateUserDetails) => {
		return state.student;
	},
);

export const getClientDetailsData = createSelector(
	getUserDetailsState,
	(state: StateUserDetails) => {
		return state.client;
	},
);

export const getStudentFavouriteUnis = createSelector(
	getUserDetailsState,
	(state: StateUserDetails) => {
		return state.universities;
	},
);

export const getStudentFavouriteCourses = createSelector(
	getUserDetailsState,
	(state: StateUserDetails) => {
		return state.courses;
	},
);

export const getDetailsSuccessData = createSelector(
	getUserDetailsState,
	(state: StateUserDetails) => {
		return {
			success: state.success,
			error: state.error,
		};
	},
);

export const getRegionOptions = createSelector(
	getUserDetailsState,
	(state: StateUserDetails) => state.regions,
);
