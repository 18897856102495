<div class="container my-6">
	<div class="flex flex-col items-center justify-center px-8 py-12 border rounded-lg">
		<p class="text-gray-600">
			An unexpected error occurred.&nbsp;<a
				class="blue-link-underline"
				[href]="route"
				>Please try again</a
			>.
		</p>
		<!-- Inject dynamic content -->
		<ng-content></ng-content>
	</div>
</div>
