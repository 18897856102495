import { DOCUMENT } from '@angular/common';
import {
	ApplicationRef,
	ComponentRef,
	Directive,
	ElementRef,
	HostListener,
	Inject,
	Input,
	OnChanges,
	SimpleChanges,
	ViewContainerRef,
} from '@angular/core';
import { TooltipComponent } from './tooltip.component';

@Directive({
	selector: '[ucTooltip]',
	standalone: true,
})
export class TooltipDirective implements OnChanges {
	@Input({ required: true }) tooltipTitle = '';
	@Input() tooltipDesc = '';

	private _tooltipComponent: ComponentRef<TooltipComponent> | undefined;
	private _touched = false;

	constructor(
		@Inject(DOCUMENT) private _document: Document,
		private _viewContainerRef: ViewContainerRef,
		private _elementRef: ElementRef,
		private _appRef: ApplicationRef,
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		const title = changes['tooltipTitle']?.currentValue;
		if (title) {
			this._tooltipComponent?.setInput('title', this.tooltipTitle);
		}
	}

	@HostListener('mouseenter')
	@HostListener('touchstart')
	onMouseEnter(): void {
		this._initialiseTooltipComponent();
	}

	@HostListener('touchstart')
	onTouch() {
		if (!this._touched) {
			this._initialiseTooltipComponent();
		} else {
			this._closeTooltip();
		}
		this._touched = !this._touched;
	}

	@HostListener('mouseleave')
	onMouseLeave(): void {
		this._closeTooltip();
	}

	@HostListener('mousewheel', ['$event'])
	onMouseScroll(event: WheelEvent): void {
		if (event.deltaY !== 0) {
			this._closeTooltip();
		}
	}

	private _initialiseTooltipComponent() {
		if (this._tooltipComponent) {
			return;
		}

		this._viewContainerRef.clear();
		this._tooltipComponent = this._viewContainerRef.createComponent(TooltipComponent);
		this._document.body.appendChild(this._tooltipComponent.location.nativeElement);
		this._setTooltipComponentProperties();
		this._tooltipComponent.hostView.detectChanges();
	}

	private _closeTooltip() {
		if (!this._tooltipComponent) {
			return;
		}
		this._appRef.detachView(this._tooltipComponent.hostView);
		setTimeout(() => {
			if (this._tooltipComponent) {
				this._tooltipComponent.destroy();
				this._tooltipComponent = undefined;
			}
		});
	}

	private _setTooltipComponentProperties() {
		if (!this._tooltipComponent) {
			return;
		}
		this._tooltipComponent.instance.title = this.tooltipTitle;
		this._tooltipComponent.instance.description = this.tooltipDesc;
		const elementRect = this._elementRef.nativeElement.getBoundingClientRect();
		const { left, right, top } = elementRect;
		this._tooltipComponent.instance.left = (right - left) / 2 + left;
		this._tooltipComponent.instance.top = top;
	}
}
