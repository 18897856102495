import { CommonModule } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	viewChild,
} from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';
@Component({
	selector: 'uc-tooltip',
	imports: [CommonModule],
	templateUrl: './tooltip.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('fadeInOut', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate(300, style({ opacity: 1 })),
			]),
			transition(':leave', [animate(300, style({ opacity: 0 }))]),
		]),
	],
})
export class TooltipComponent implements AfterViewInit {
	@Input() title = '';
	@Input() description = '';
	@Input() left = 0;
	@Input() top = 0;

	tooltipElem = viewChild<ElementRef>('tooltipContent');

	ngAfterViewInit() {
		this.adjustTooltipPosition();
	}

	adjustTooltipPosition() {
		const tooltipElem = this.tooltipElem()?.nativeElement;
		if (!tooltipElem) return;

		const tooltipRect = tooltipElem.getBoundingClientRect();
		const { right, left, width } = tooltipRect;
		const margin = 8;

		const overflowRight = right - window.innerWidth;
		const overflowLeft = left;
		if (overflowRight > 0) {
			const translateX = Math.abs(width / 2 + overflowRight + margin);
			tooltipElem.style.transform = `translateX(${-translateX}px) translateY(-100%)`;
		} else if (overflowLeft < 0) {
			const adjustedLeft = this.left - margin;
			tooltipElem.style.transform = `translateY(-100%)`;
			tooltipElem.style.left = `${-adjustedLeft}px`;
		}
	}
}
