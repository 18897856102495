import { Injectable, signal } from '@angular/core';
import { DegreeLevelOption } from '@uc/web/shared/data-models';

@Injectable({ providedIn: 'root' })
export class DegreeLevelService {
	degreeLevels = signal<DegreeLevelOption[]>([
		{ label: 'Undergraduate', link: 'undergraduate' },
		{ label: 'Postgraduate', link: 'postgraduate' },
	]);

	selectedDegreeLevel = signal<DegreeLevelOption>(this.degreeLevels()[0]);
}
