import { Action, createReducer, on } from '@ngrx/store';
import {
	fetchClientDetails,
	fetchStudentDetails,
	fetchStudentFavourites,
	loadUserDetailsError,
	removeFavouriteCourse,
	removeFavouriteUniversity,
	resetClientDetails,
	resetStudentDetails,
	updateClientDetails,
	updateStudentApplicationDetails,
	updateStudentDetails,
	updateStudentPersonalDetails,
	updateStudentFavourites,
	updateRegionOptions,
	fetchRegionsOptions,
} from './user-details.actions';
import {
	ClientDetails,
	Region,
	StudentDetails,
	UserFavouritesCourse,
	UserFavouritesUniversity,
} from '@uc/web/shared/data-models';

export const _STATE_USER_DETAILS_FEATURE_KEY = 'stateUserDetails';

export interface StateUserDetails {
	student: StudentDetails | null;
	client: ClientDetails | null;
	isLoading: boolean;
	error: any;
	success: boolean | null;
	courses: UserFavouritesCourse[] | null;
	universities: UserFavouritesUniversity[] | null;
	regions: Region[] | null;
}

const initialState: StateUserDetails = {
	student: null,
	client: null,
	isLoading: false,
	error: null,
	success: null,
	universities: null,
	courses: null,
	regions: null,
};

const reducer = createReducer(
	initialState,
	on(
		fetchStudentDetails,
		fetchClientDetails,
		updateStudentPersonalDetails,
		updateStudentApplicationDetails,
		fetchStudentFavourites,
		(state) => ({
			...state,
			error: null,
			success: null,
			isLoading: true,
		}),
	),

	on(fetchRegionsOptions, (state) => ({
		...state,
		regions: null,
		error: null,
		success: null,
		isLoading: true,
	})),

	on(updateStudentDetails, (state, { student }) => ({
		...state,
		student: student,
		success: true,
		error: null,
		isLoading: false,
	})),

	on(updateStudentFavourites, (state, data) => {
		return {
			...state,
			universities: data.universities,
			courses: data.courses,
		};
	}),

	on(removeFavouriteCourse, (state, favourite) => {
		const filteredCourses =
			state.courses?.filter((course) => course.id !== favourite.id) ||
			state.courses;

		return {
			...state,
			courses: filteredCourses,
		};
	}),

	on(removeFavouriteUniversity, (state, favourite) => {
		const filteredUniversities =
			state.universities?.filter((uni) => uni.id !== favourite.id) ||
			state.universities;

		return {
			...state,
			universities: filteredUniversities,
		};
	}),

	on(updateClientDetails, (state, { client }) => ({
		...state,
		client: client,
		error: null,
		isLoading: false,
	})),

	on(resetStudentDetails, (state) => ({
		...state,
		student: null,
		isLoading: false,
		success: null,
		error: null,
	})),

	on(resetClientDetails, (state) => ({
		...state,
		client: null,
		isLoading: false,
		success: null,
		error: null,
	})),

	on(loadUserDetailsError, (state, { error }) => ({
		...state,
		error,
		success: false,
		isLoading: false,
	})),
	on(updateRegionOptions, (state, { regions }) => ({
		...state,
		regions,
	})),
);

export function userDetailsReducer(state: StateUserDetails | undefined, action: Action) {
	return reducer(state, action);
}
