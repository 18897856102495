import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RecaptchaInfoComponent } from '../recaptcha-info/recaptcha-info.component';

import { RouterModule } from '@angular/router';

@Component({
	selector: 'uc-privacy-policy',
	templateUrl: './privacy-policy.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [RecaptchaInfoComponent, RouterModule],
})
export class PrivacyPolicyComponent {
	@Input() policyTitle!: string;
	@Input() universityName!: string;
	@Input() text!: string;
	@Input() policyLink!: string;
	@Input() showUcPrivacyPolicy = false;
	@Input() showRecaptchaInfo = false;
}
