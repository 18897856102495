import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EntitiesPipe, TruncatePipe } from '@uc/utilities';

@Component({
	selector: 'uc-description',
	templateUrl: './description.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [TruncatePipe, EntitiesPipe, CommonModule],
})
export class DescriptionComponent {
	@Input() truncateMobileOnly = false;
	@Input() description: string | undefined = '';
	@Input() charLimit = 590;
	@Input() shortLength = 590;
	@Input() customPaddingTop = 'pt-2 md:pt-8';
}
