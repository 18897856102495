import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';

@Injectable()
export class FilterTogglerService {
	displayFilter = false;
	private _platformId = inject(PLATFORM_ID);
	private _document = inject(DOCUMENT);

	toggle(showFilter?: boolean): void {
		if (showFilter === false || showFilter === true) {
			this.displayFilter = showFilter;
		} else {
			this.displayFilter = !this.displayFilter;
		}

		if (isPlatformBrowser(this._platformId)) {
			window.scrollTo(0, 0);
			const element = this._document.getElementsByTagName('body')[0];
			if (this.displayFilter) {
				element?.classList.add('overflow-y-hidden');
			} else {
				element?.classList.remove('overflow-y-hidden');
			}
		}
	}
}
