import { Component, Input, Optional, forwardRef } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormErrorComponent } from '../form-error/form-error.component';
import { BaseControlComponent } from '../base-control/base-control.component';

@Component({
	selector: 'uc-consent-toggle',
	imports: [CommonModule, FormErrorComponent],
	templateUrl: './consent-toggle.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ConsentToggleComponent),
			multi: true,
		},
	],
})
export class ConsentToggleComponent extends BaseControlComponent {
	@Input({ required: true }) labelText!: string;

	constructor(@Optional() protected controlContainer: ControlContainer) {
		super(controlContainer);
	}

	updateValue(event: Event): void {
		const target = event.target as HTMLInputElement;
		const value = target.checked;
		this.value = value;
		this.onChange(this.value);
	}
}
