import { NgClass } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormErrorSvgComponent } from '@uc/shared/svg';

@Component({
	selector: 'uc-form-error',
	templateUrl: './form-error.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [FormErrorSvgComponent, NgClass],
})
export class FormErrorComponent {
	@Input() formError!: boolean;
	@Input() fieldError!: string;
}
