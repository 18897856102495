import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	standalone: true,
	name: 'ucStripStatementTitle',
})
export class StripStatementTitlePipe implements PipeTransform {
	transform(text: string): string {
		if (!text) return text;

		return text.replace(
			/<h2>Why do you want to study this course or subject\?<\/h2>/g,
			'',
		);
	}
}
