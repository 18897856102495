import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class NavLinkClickService {
	private linkClickSubject = new Subject<string>();

	linkClick$ = this.linkClickSubject.asObservable();

	onLinkClick(value: string, key?: string): void {
		const link = key ? `${key}=${value}` : value;

		this.linkClickSubject.next(link);
	}
}
