import { createAction, props } from '@ngrx/store';
import {
	ClientDetails,
	StudentDetails,
	ChangeUserDetailsPayload,
	ChangeApplicationDetailsPayload,
	UserFavouritesUniversity,
	UserFavouritesCourse,
	Region,
} from '@uc/web/shared/data-models';

export const fetchStudentDetails = createAction(
	'[UserDetails/API] Fetch Student Details',
);

export const fetchClientDetails = createAction('[UserDetails/API] Fetch Client Details');

export const updateStudentDetails = createAction(
	'[UserDetails] Update Student Details',
	props<{ student: StudentDetails | null }>(),
);

export const updateStudentPersonalDetails = createAction(
	'[UserDetails] Update Student Personal Details',
	props<{ student: ChangeUserDetailsPayload }>(),
);

export const updateStudentApplicationDetails = createAction(
	'[UserDetails] Update Student Application Details',
	props<{ student: ChangeApplicationDetailsPayload }>(),
);

export const fetchStudentFavourites = createAction(
	'[StateUserProfile/API] Fetch User Favourites',
);

export const updateStudentFavourites = createAction(
	'[StateUserProfile] Update User Favourites',
	props<{
		universities: UserFavouritesUniversity[];
		courses: UserFavouritesCourse[];
	}>(),
);

export const removeFavouriteCourse = createAction(
	'[StateUserProfile] Remove Favourite Course',
	props<{ id: string }>(),
);

export const removeFavouriteUniversity = createAction(
	'[StateUserProfile] Remove Favourite University',
	props<{ id: number }>(),
);

export const resetStudentDetails = createAction(
	'[UserDetails] Reset resetStudentDetails Details',
);

export const updateClientDetails = createAction(
	'[UserDetails] Update Client Details',
	props<{ client: ClientDetails | null }>(),
);

export const resetClientDetails = createAction('[UserDetails] Reset Client Details');

export const loadUserDetailsError = createAction(
	'[UserDetails] Load User Details Error',
	props<{ error: any }>(),
);

export const fetchRegionsOptions = createAction(
	'[StateUserProfile/API] Fetch Regions Options',
	props<{ country: { id: number; name: string } }>(),
);

export const updateRegionOptions = createAction(
	'[StateUserProfile] Update Region Options',
	props<{ regions: Region[] }>(),
);
