import { Injectable, inject } from '@angular/core';
import { DataLayerService } from '../data-layer/data-layer.service';

@Injectable({ providedIn: 'root' })
export class StatementsGTMService {
	private dataLayerSrv = inject(DataLayerService);

	/**
	 * When user clicks any recommended statement in individual statement page
	 */

	recommendedStatementsClick(subject: string) {
		this.dataLayerSrv.push({
			eventName: 'recommended_statement_click',
			subject: subject,
			event: 'recommendedStatementsClick',
		});
	}

	/**
	 * When user clicks search courses button in statements category header
	 */

	searchCategoryClick(subjectName: string) {
		this.dataLayerSrv.push({
			eventName: 'search_category_button_click',
			subject: subjectName,
			event: 'searchCategoryClick',
		});
	}
}
