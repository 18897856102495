@if (parentForm(); as form) {
	<div [formGroup]="form">
		<div
			formGroupName="phoneDetailsForm"
			class="flex border rounded-lg shadow-sm"
			[ngClass]="{
				'border-gray-300': showDarkBorder,
				'border-red-600':
					formSubmitted() &&
					(phone.hasError('phoneInvalid') ||
						phone.hasError('required') ||
						countryCode.hasError('required')),
			}"
		>
			<uc-phone-country-code-field
				formControlName="countryCode"
				controlName="countryCode"
				[formSubmitted]="formSubmitted()"
			/>
			<!--
				note: formSubmitted is set to false
				so only the error messages within the phone-field.component below get triggered -->
			<uc-input-field
				formControlName="phone"
				inputType="tel"
				controlName="phone"
				[placeholder]="placeholder()"
				autocomplete="tel"
				labelText="Mobile Number*"
				class="w-full"
				customStyles="!border-l-0 !rounded-r-md !rounded-l-none !shadow-none !border-0"
				[formSubmitted]="false"
			/>
		</div>
	</div>
}

@if (
	formSubmitted() && phone.hasError('phoneInvalid') && !phone.hasError('required');
	as showCustomError
) {
	<div class="pb-4">
		<uc-form-error [fieldError]="errorMessage()" [formError]="showCustomError" />
	</div>
}

<uc-form-error
	fieldError="Please enter your phone number."
	[formError]="formSubmitted() && phone.hasError('required')"
/>

<uc-form-error
	fieldError="Please select a country calling code."
	[formError]="formSubmitted() && countryCode.hasError('required')"
/>
