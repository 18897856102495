import { Component, input } from '@angular/core';

import { InAppBrowserMessageHandlerService } from '@uc/web/core';

@Component({
	selector: 'uc-recaptcha-info',
	templateUrl: './recaptcha-info.component.html',
	imports: [],
})
export class RecaptchaInfoComponent {
	isInAppBrowser = input<boolean>();

	constructor(
		private _inAppBrowserMessageHandlerSrv: InAppBrowserMessageHandlerService,
	) {}

	onPrivacyPolicy(link: string) {
		this._inAppBrowserMessageHandlerSrv.ios.onPrivacyPolicy(link);
		this._inAppBrowserMessageHandlerSrv.android.onPrivacyPolicy(link);
	}
}
