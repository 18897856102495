import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ClearingService {
	today = new Date();
	currentYear = new Date().getFullYear();

	// 1st of Jan - 26th of June
	private readonly _preClearingDates = {
		startDay: 1,
		startMonth: 0,
		endDay: 4,
		endMonth: 6,
	};

	// 27th of June - 30th of September
	private readonly _clearingDates = {
		startDay: 5,
		startMonth: 6,
		endDay: 30,
		endMonth: 8,
	};

	// 1st of October - 31st of December
	private readonly _postClearingDates = {
		startDay: 1,
		startMonth: 9,
		endDay: 31,
		endMonth: 11,
	};

	// pre clearing
	private readonly startPreClearing = new Date(
		this.currentYear,
		this._preClearingDates.startMonth,
		this._preClearingDates.startDay,
	);

	private readonly endPreClearing = new Date(
		this.currentYear,
		this._preClearingDates.endMonth,
		this._preClearingDates.endDay,
	);

	// clearing
	private readonly startClearing = new Date(
		this.currentYear,
		this._clearingDates.startMonth,
		this._clearingDates.startDay,
	);

	private readonly endClearing = new Date(
		this.currentYear,
		this._clearingDates.endMonth,
		this._clearingDates.endDay,
	);

	// post clearing
	private readonly startPostClearing = new Date(
		this.currentYear,
		this._postClearingDates.startMonth,
		this._postClearingDates.startDay,
	);

	private readonly endPostClearing = new Date(
		this.currentYear,
		this._postClearingDates.endMonth,
		this._postClearingDates.endDay,
	);

	private readonly _dates = {
		startDay: '5th',
		startMonth: 'July',
		endDay: '30th',
		endMonth: 'September',
	};

	getStartDateString() {
		return `${this._dates.startDay} ${this._dates.startMonth} ${this.getYear()}`;
	}

	getYear(): number {
		let year = this.currentYear;

		if (this.today > this.endClearing) {
			year = this.currentYear + 1;
		}

		return year;
	}

	isClearing() {
		return this.today >= this.startClearing && this.today <= this.endClearing;
	}

	isPreClearing() {
		return this.today >= this.startPreClearing && this.today <= this.endPreClearing;
	}

	isPostClearing() {
		return this.today >= this.startPostClearing && this.today <= this.endPostClearing;
	}

	generateData() {
		const year = this.getYear();
		const heroSection = {
			title: `Search UK University Clearing Courses ${year}`,
			description:
				'Easily find the perfect Clearing course with our search tool. Discover and compare thousands of options from UK universities.',
		};

		const infoSection = {
			title: `Find Your Clearing ${year} Course`,
			description: `Clearing is a process that matches you with universities that have space on their courses. Whether you've changed your mind about where you want to study or missed out on a place elsewhere, finding the right UCAS Clearing course for you has never been easier.`,
		};

		const seoSection = {
			title: `University Clearing Courses ${year} | Undergraduate & Postgraduate | UniCompare`,
			description: `Apply for UCAS Clearing courses for ${year} entry here. See which university courses are best for you from our dedicated Clearing courses list.`,
		};

		const searchSection = this._generateQuickSearchCopy(year);

		return {
			heroSection,
			infoSection,
			seoSection,
			searchSection,
		};
	}

	private _generateQuickSearchCopy(year: number) {
		if (this.today >= this.startClearing && this.today <= this.endClearing) {
			return {
				title: 'Courses Available in Clearing',
				description: `Quickly discover available courses with our Clearing ${this.currentYear} Course Links. Easily access more info about entry requirements, course quality fees and funding, and university contact details.`,
			};
		} else {
			return {
				title: `Courses Available in Clearing (Opens ${this._dates.startDay} ${this._dates.startMonth} ${year})`,
				description: `Clearing currently opens on the ${this._dates.startDay} of ${this._dates.startMonth} ${year}, and closes on the ${this._dates.endDay} of ${this._dates.endMonth} ${year}. In the meantime, check out our undergraduate courses.`,
			};
		}
	}
}
