export enum Offset {
	Off = 0,
	On = 1,
}

export type DegreeLevelPayload =
	| 'ALL_UNDERGRADUATE'
	| 'ALL_POSTGRADUATE'
	| 'PG_CERTIFICATE_AND_DIPLOMA'
	| 'ACCESS_TO_HIGHER_EDUCATION'
	| 'BACHELOR'
	| 'FOUNDATION'
	| 'HNC'
	| 'HND'
	| 'MASTER'
	| 'PGCE'
	| 'PHD'
	| 'PG_CERTIFICATE'
	| 'PG_DIPLOMA'
	| 'UG_CERTIFICATE_AND_DIPLOMA';

export type CourseSearchPillUrlKey =
	| 'product'
	| 'ucaspts'
	| 'loc'
	| 'mode'
	| 'ctype'
	| 'utype';

export type CourseSearchLocationGA =
	| 'Top Search Bar'
	| 'Header Search Bar'
	| 'Search Courses Banner'
	| 'Course Search Filter'
	| 'Related Subjects'
	| 'Hub Page Subject Link'
	| 'Directory Page Subject Link'
	| 'Statement Header Button'
	| 'Default';

export enum CourseSearchErrorsEnum {
	NoResults = 'No Results',
	NoSearchTerm = 'No Search Term',
	TooManyFilters = 'Too many filters',
}

export type CourseSearchErrors = `${CourseSearchErrorsEnum}`;
